export default {
  Entities: [
    {
      Properties: {
        FilterViewId: '0',
        Name: 'MyActiveProjects',
        IsDefault: true,
        Created: '2024-8-12T17:15:33.008Z',
        LastModified: '2024-8-12T17:15:33.008Z',
        FilterValues: [
          {
            Name: 'ProjectManager',
            Value: [
              {
                Key: 'NJonas Gam Nielsen',
                Value: '1175',
              },
            ],
            IsInclusive: true,
            FilterType: 'MultiSelectTypeSearch',
          },
        ],
      },
    },
    {
      Properties: {
        FilterViewId: '1',
        Name: 'ActiveProjects',
        IsDefault: false,
        Created: '2024-8-12T17:15:33.008Z',
        LastModified: '2024-8-12T17:15:33.008Z',
        FilterValues: [
          {
            Name: 'ProjectStatus',
            Value: [
              {
                Key: 'Active projects',
                Value: '-1',
              },
            ],
            IsInclusive: true,
            FilterType: 'MultiSelectTypeSearch',
          },
        ],
      },
    },
    {
      Properties: {
        FilterViewId: '2',
        Name: 'AllProjects',
        IsDefault: false,
        Created: '2024-8-12T17:15:33.008Z',
        LastModified: '2024-8-12T17:15:33.008Z',
        FilterValues: [
          {
            Name: 'ProjectStatus',
            Value: [
              {
                Key: 'Active projects',
                Value: '-1',
              },
            ],
            IsInclusive: true,
            FilterType: 'MultiSelectTypeSearch',
          },
          {
            Name: 'SearchText',
            Value: '_TIM',
            IsInclusive: true,
            FilterType: 'Textbox',
          },
        ],
      },
    },
  ],
  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/filter-view',
      Rel: 'self',
    },
  ],
};
