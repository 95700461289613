import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Tab, Tabs } from '@mui/material';
import { z } from 'zod';
import {
  ISavedViewForm,
  SnackbarMode,
  useDeleteSavedView,
  usePostSavedView,
  useUpdateSavedView,
  TSnackbarKeys,
  INewSavedView,
} from 'src/apis/savedViewAPI';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Button, ToastifyAlert } from 'src/components/mui-components';
import { toast } from 'react-toastify';
import { TViewOptions } from 'src/screens/SearchProjects/types/searchProjects';
import {
  preDefinedFilters,
  SEARCH_TEXT_FILTER,
  SNACKBAR_CONFIG,
} from 'src/screens/SearchProjects/constants';
import isEqual from 'lodash/isEqual';
import { useGetCurrentPageIdentifier } from 'src/stores/PageStore';
import { useFilterStore } from 'src/stores/FilterStore';
import { IFilterProps } from 'src/reducers/FilterReducer/FilterReducer';
import { translationAnyText } from 'src/utils/translation';
import { FilterValues } from 'src/apis/savedViewAPI/savedViewAPI';
import { SaveViewDialog } from './components/SaveViewDialog';
import { EditViewDialog } from './components/EditViewDialog';

interface IFilterList {
  savedViewsList: INewSavedView[];
  tabOnChange: (id: string) => void;
  activeView: string;
  setActiveView: Dispatch<SetStateAction<string>>;
  changedViewOptions?: TViewOptions;
  constructFilterPayload: (payload: FilterValues[]) => Record<string, IFilterProps>;
}

export const SaveViewContainer = ({
  savedViewsList,
  tabOnChange,
  activeView,
  setActiveView,
  changedViewOptions,
  constructFilterPayload,
}: IFilterList) => {
  const { t } = useTranslation('savedView');
  const pageIdentifier = useGetCurrentPageIdentifier();
  const { selectedFilterList } = useFilterStore();

  const { mutate: postSavedView } = usePostSavedView();
  const { mutate: updateSavedView } = useUpdateSavedView();
  const { mutate: deleteSavedView } = useDeleteSavedView();

  const [haveFilterChanges, setHaveFilterChanges] = useState(false);
  const [haveOptionChanges, setHaveOptionChanges] = useState(false);
  const [recentAction, setRecentAction] = useState<string>('');

  // region Save View Form
  const formSchema = z.object({
    title: z.string().min(1, { message: 'Please enter title' }),
    isDefault: z.boolean(),
  });

  const form = useForm({
    defaultValues: {
      title: '',
      isDefault: false,
    } as ISavedViewForm,
    resolver: zodResolver(formSchema),
  });

  const displayToast = (type: SnackbarMode | 'error') => {
    const config = SNACKBAR_CONFIG.find((c) => c.type === type);
    const { title, description } = config ?? {};

    if (type === 'error') {
      toast.error(
        <ToastifyAlert
          description={t('UnknownErrorMessage')}
          data-automation-id="ToastSaveViewError"
        />,
        {
          autoClose: 5000,
          closeButton: false,
        },
      );
    } else {
      toast.success(
        <ToastifyAlert
          title={t(title as TSnackbarKeys)}
          description={t(description as TSnackbarKeys)}
        />,
        {
          autoClose: 5000,
          closeButton: false,
        },
      );
    }
  };

  const [saveViewDialog, setSaveViewDialog] = useState(false);
  const [editViewDialog, setEditViewDialog] = useState(false);

  const selectedSaveViewData = savedViewsList.find((view) => view.filterViewId === activeView);

  if (recentAction && savedViewsList.length > 0) {
    // To handle select newly created tab view after post
    if (recentAction === 'save') {
      const latestView = savedViewsList?.reduce((latest, current) =>
        new Date(latest.lastModified) > new Date(current.lastModified) ? latest : current,
      );
      setActiveView(latestView.filterViewId ?? '');
      // To handle select first tab view after delete
    } else if (recentAction === 'delete') {
      setActiveView(savedViewsList.length > 0 ? savedViewsList[0].filterViewId ?? '' : '');
    }
  }

  const formatFilters = (filters: Record<string, IFilterProps>) =>
    Object.keys(filters).map((key) => {
      const filter = filters[key];
      return {
        name: filter.label,
        value: filter.values.map((item) => item.value).join(','),
        isInclusive: filter.isInclude ?? true,
        filterType: filter.type ?? 'MultiSelectTypeSearch',
      };
    });

  const formatViewOptionFilters = (filters: TViewOptions) =>
    Object.keys(filters).map((key) => {
      const value = filters[key as keyof TViewOptions];
      // Here to expand to other view options
      return {
        ...SEARCH_TEXT_FILTER,
        value,
      };
    });

  const onSaveSubmit = async (actionType: string, data: ISavedViewForm) => {
    const formattedFilters = formatFilters(selectedFilterList);
    let formattedViewOptionsFilters: any = [];

    if (changedViewOptions) {
      formattedViewOptionsFilters = formatViewOptionFilters(changedViewOptions);
    }

    if (actionType === 'save') {
      postSavedView(
        {
          pageIdentifier,
          postModel: {
            name: data.title ?? '',
            isDefault: data.isDefault ?? false,
            filterValues: [...formattedFilters, ...formattedViewOptionsFilters],
          },
        },
        {
          onError: () => {
            displayToast('error');
          },
          onSuccess: () => {
            displayToast('save');
            setRecentAction('save');
          },
        },
      );
    } else if (actionType === 'changes') {
      await updateSavedView(
        {
          pageIdentifier,
          updateModel: {
            filterViewId: activeView,
            name: data.title ?? '',
            isDefault: data.isDefault ?? false,
            filterValues: [...formattedFilters, ...formattedViewOptionsFilters],
          },
        },
        {
          onError: () => {
            displayToast('error');
          },
          onSuccess: () => {
            displayToast('changes');
            setRecentAction('changes');
          },
        },
      );
    }
    form.reset();
    setSaveViewDialog(false);
  };

  const onEditSubmit = async (actionType: string, data: ISavedViewForm) => {
    const formattedFilters = formatFilters(selectedFilterList);
    let formattedViewOptionsFilters: any = [];

    if (changedViewOptions) {
      formattedViewOptionsFilters = formatViewOptionFilters(changedViewOptions);
    }

    if (actionType === 'edit') {
      await updateSavedView(
        {
          pageIdentifier,
          updateModel: {
            filterViewId: activeView,
            name: data.title ?? '',
            isDefault: data.isDefault ?? false,
            filterValues: [...formattedFilters, ...formattedViewOptionsFilters],
          },
        },
        {
          onError: () => {
            displayToast('error');
          },
          onSuccess: () => {
            displayToast('edit');
          },
        },
      );
    } else if (actionType === 'delete') {
      await deleteSavedView(activeView, {
        onError: () => {
          displayToast('error');
        },
        onSuccess: () => {
          displayToast('delete');
          setRecentAction('delete');
        },
      });
    }
    form.reset();
    setEditViewDialog(false);
  };

  const translatePreDefinedFilter = (filter: string) => {
    if (preDefinedFilters.includes(filter)) {
      return translationAnyText(t, `SavedView${filter}TabText`);
    }
    return filter;
  };

  const handleViewAction = (action: string) => {
    if (action === 'save') {
      setSaveViewDialog(true);
    } else {
      if (selectedSaveViewData) {
        form.setValue('title', translatePreDefinedFilter(selectedSaveViewData.name), {
          shouldDirty: true,
          shouldValidate: true,
        });
        form.setValue('isDefault', selectedSaveViewData.isDefault);
      }
      setEditViewDialog(true);
    }
  };

  const hasChanges = useMemo(
    () => haveFilterChanges || haveOptionChanges,
    [haveFilterChanges, haveOptionChanges],
  );

  // Compare changes for filters and view
  useEffect(() => {
    const selectedView = savedViewsList?.find((view) => view.filterViewId === activeView);

    // Compare changes for filters
    if (selectedFilterList !== undefined) {
      setHaveFilterChanges(
        !isEqual(constructFilterPayload(selectedView?.filterValues ?? []), selectedFilterList),
      );
    }

    // Compare changes for view options
    if (changedViewOptions) {
      setHaveOptionChanges(
        !isEqual(
          formatViewOptionFilters(changedViewOptions),
          (selectedView?.viewOptions?.length ?? 0) > 0
            ? selectedView?.viewOptions
            : formatViewOptionFilters({ searchText: '' }),
        ),
      );
    }
  }, [activeView, changedViewOptions, constructFilterPayload, savedViewsList, selectedFilterList]);

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center" sx={{ flexGrow: 1 }}>
          {activeView && (
            <Tabs
              value={activeView}
              onChange={(_, v) => {
                setRecentAction('');
                tabOnChange(v);
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              {savedViewsList.map((v) => (
                <Tab
                  key={v.filterViewId}
                  value={v.filterViewId}
                  label={translatePreDefinedFilter(v.name)}
                  wrapped
                  aria-current={activeView === v.filterViewId}
                  data-automation-id={`FilterContainer${v.name.replace(/ /g, '')}Tab`}
                  onClick={() => {
                    if (activeView === v.filterViewId) {
                      setRecentAction('');
                      tabOnChange(activeView);
                    }
                  }}
                />
              ))}
            </Tabs>
          )}
        </Stack>
        <Stack direction="row" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            disabled={!hasChanges}
            onClick={() => handleViewAction('save')}
            data-automation-id="SavedViewSaveViewButton"
          >
            {t('SaveViewButtonText')}
          </Button>
          <Button
            variant="outlined"
            size="small"
            disabled={!selectedSaveViewData}
            onClick={() => handleViewAction('edit')}
            data-automation-id="SavedViewEditViewButton"
          >
            {t('EditViewButtonText')}
          </Button>
        </Stack>
      </Stack>
      <SaveViewDialog
        showSaveDialog={saveViewDialog}
        onClose={() => setSaveViewDialog(false)}
        form={form}
        onSubmit={onSaveSubmit}
        selectedSaveViewData={selectedSaveViewData}
      />
      <EditViewDialog
        showEditDialog={editViewDialog}
        onClose={() => setEditViewDialog(false)}
        form={form}
        onSubmit={onEditSubmit}
      />
    </>
  );
};
