import { Card, CardContent, Typography } from '@mui/material';
import { IAddedValuePerMonthWidgetDataItem } from 'src/apis/reportingWidgetsAPI/types/addedValuePerMonth';

interface CustomTooltipProps {
  axisValue: string | number | Date | null;
  data: IAddedValuePerMonthWidgetDataItem[];
  alignment: string;
  currencyAbb: string;
  t: (key: string) => string;
  valueFormatter: (value: number) => string;
}

export const CustomTooltip = ({
  axisValue,
  data,
  alignment,
  currencyAbb,
  t,
  valueFormatter,
}: CustomTooltipProps) => {
  const dataPoint = data.find((item) => item.month === axisValue);

  const renderAmountSection = (title: string, amount: number, currency: string) => (
    <Typography gutterBottom>
      {title}: {valueFormatter(amount)} {currency ?? ''}
    </Typography>
  );

  return (
    <Card sx={{ minWidth: 200 }}>
      <CardContent>
        <Typography component="h3" gutterBottom>
          <strong>{String(axisValue)}</strong>
        </Typography>
        {alignment === 'reg'
          ? renderAmountSection(
              t('AddedValuePerMonthRegAmount'),
              dataPoint?.amountRegistered ?? 0,
              currencyAbb,
            )
          : renderAmountSection(
              t('AddedValuePerMonthEstAmount'),
              dataPoint?.amountEstimated ?? 0,
              currencyAbb,
            )}
        <Typography gutterBottom>
          {t('AddedValuePerMonthHours')}:{' '}
          {valueFormatter(
            alignment === 'reg' ? dataPoint?.hours ?? 0 : dataPoint?.hoursEstimated ?? 0,
          )}
        </Typography>
        <Typography>
          {t('AverageHourlyRate')} ({alignment === 'reg' ? t('RegText') : t('EstText')}):{' '}
          {alignment === 'reg'
            ? valueFormatter(
                dataPoint?.hours && dataPoint.hours !== 0
                  ? (dataPoint.amountRegistered ?? 0) / dataPoint.hours
                  : dataPoint?.amountRegistered ?? 0,
              )
            : valueFormatter(
                dataPoint?.hoursEstimated && dataPoint.hoursEstimated !== 0
                  ? (dataPoint.amountEstimated ?? 0) / dataPoint.hoursEstimated
                  : dataPoint?.amountEstimated ?? 0,
              )}{' '}
          {currencyAbb ?? ''}
        </Typography>
        <br />
        <Typography component="h3" gutterBottom>
          <strong>{t('YearToDateText')}</strong>
        </Typography>
        {alignment === 'reg'
          ? renderAmountSection(
              t('AddedValuePerMonthRegAmount'),
              dataPoint?.registeredAmountYTD ?? 0,
              currencyAbb,
            )
          : renderAmountSection(
              t('AddedValuePerMonthEstAmount'),
              dataPoint?.estimatedAmountYTD ?? 0,
              currencyAbb,
            )}
        <Typography gutterBottom>
          {t('AddedValuePerMonthHours')}:{' '}
          {valueFormatter(
            alignment === 'reg' ? dataPoint?.hoursYTD ?? 0 : dataPoint?.hoursEstimatedYTD ?? 0,
          )}
        </Typography>
        <Typography>
          {t('AverageHourlyRate')} ({alignment === 'reg' ? t('RegText') : t('EstText')}):{' '}
          {alignment === 'reg'
            ? valueFormatter(
                dataPoint?.hoursYTD && dataPoint.hoursYTD !== 0
                  ? (dataPoint.registeredAmountYTD ?? 0) / dataPoint.hoursYTD
                  : dataPoint?.registeredAmountYTD ?? 0,
              )
            : valueFormatter(
                dataPoint?.hoursEstimatedYTD && dataPoint.hoursEstimatedYTD !== 0
                  ? (dataPoint.estimatedAmountYTD ?? 0) / dataPoint.hoursEstimatedYTD
                  : dataPoint?.estimatedAmountYTD ?? 0,
              )}{' '}
          {currencyAbb ?? ''}
        </Typography>
      </CardContent>
    </Card>
  );
};
