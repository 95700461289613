import { Dispatch, ReactNode, SetStateAction, useCallback, useEffect } from 'react';
import { INewSavedView } from 'src/apis/savedViewAPI';
import { Deck } from 'src/components/ui-components';
import { useFilterDispatch, useFilterStore } from 'src/stores/FilterStore';
import { IFilterProps } from 'src/reducers/FilterReducer/FilterReducer';
import { TFilterType } from 'src/apis/types/filterListAPI';
import { FilterValues } from 'src/apis/savedViewAPI/savedViewAPI';
import isEqual from 'lodash/isEqual';
import { TViewOptions } from 'src/screens/SearchProjects/types/searchProjects';
import { SaveViewContainer } from '../SaveViewContainer';

interface IMainContainer {
  children: ReactNode;
  savedViewsList: INewSavedView[];
  changedViewOptions?: TViewOptions;
  setChangedViewOptions?: Dispatch<SetStateAction<TViewOptions | undefined>>;
  activeView: string;
  setActiveView: Dispatch<SetStateAction<string>>;
  allowSavedViews: boolean;
}

export const MainContainer = ({
  children,
  savedViewsList,
  changedViewOptions,
  setChangedViewOptions,
  activeView,
  setActiveView,
  allowSavedViews,
}: IMainContainer) => {
  const dispatch = useFilterDispatch();

  const { filterList, selectedFilterList } = useFilterStore();

  const constructFilterPayload = useCallback(
    (payload: FilterValues[]) => {
      // Create a map from filterList for quick lookups
      const filterMap: { [key: string]: Omit<IFilterProps, 'values' | 'label'> } = {};
      filterList.forEach((filterCategory) => {
        filterCategory?.filterItems?.forEach((filterItem) => {
          filterMap[filterItem.name] = filterItem;
        });
      });

      // Construct the new payload
      const newPayload: Record<string, IFilterProps> = {};
      payload.forEach((payloadItem) => {
        const filterItem = filterMap[payloadItem.name];
        if (filterItem) {
          newPayload[payloadItem.name as TFilterType] = {
            label: payloadItem.name,
            values: payloadItem.value,
            contentUrl: filterItem.contentUrl,
            childFilters: filterItem.childFilters ?? [],
            parentFilters: filterItem.parentFilters ?? [],
            isInclude: payloadItem.isInclusive,
            type: filterItem.type,
          };
        }
      });
      return newPayload;
    },
    [filterList],
  );

  const handleFilterChanges = useCallback(
    (id: string) => {
      const payload = savedViewsList?.find((v) => v.filterViewId === id)?.filterValues;
      if (dispatch) {
        dispatch({ type: 'RESET_FILTER' });
        if (payload && Object.keys(payload).length) {
          const finalPayload = constructFilterPayload(payload);
          dispatch?.({ type: 'ADD_OR_UPDATE_FILTER', payload: finalPayload });
        }
        dispatch({ type: 'DEACTIVATE_CONTAINER_ID' });
        dispatch({ type: 'DEACTIVATE_PANEL_ID' });
      }
    },
    [constructFilterPayload, dispatch, savedViewsList],
  );

  const resetViewOptions = useCallback(() => {
    const savedViewOptions = savedViewsList?.find(
      (view) => view.filterViewId === activeView,
    )?.viewOptions;

    const options = savedViewOptions?.map((item) => ({
      [item.name.charAt(0).toLowerCase() + item.name.slice(1)]: item.value,
    }));
    const optionsToObject = options ? Object.assign({}, ...options) : {};

    setChangedViewOptions?.({
      ...optionsToObject,
    });
  }, [activeView, savedViewsList, setChangedViewOptions]);

  const tabOnChange = (id: string) => {
    setActiveView(id);
    handleFilterChanges(id);
    resetViewOptions();
  };

  // To set filter on first load of saved view when there is filter and active view
  useEffect(() => {
    if (savedViewsList) {
      const payload = savedViewsList?.find((v) => v.filterViewId === activeView)?.filterValues;
      if (dispatch) {
        if (payload && Object.keys(payload).length) {
          const finalPayload = constructFilterPayload(payload);
          if (!isEqual(finalPayload, selectedFilterList)) {
            dispatch({ type: 'RESET_FILTER' });
            dispatch({ type: 'ADD_OR_UPDATE_FILTER', payload: finalPayload });
          }
          dispatch({ type: 'DEACTIVATE_CONTAINER_ID' });
          dispatch({ type: 'DEACTIVATE_PANEL_ID' });
        } else {
          dispatch({ type: 'RESET_FILTER' });
        }
      }
      if (!activeView) {
        setActiveView(savedViewsList[0]?.filterViewId || '');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedViewsList, dispatch]);

  return (
    <Deck data-automation-id="MainContainer">
      {allowSavedViews && (
        <Deck.Item hasOverflow>
          <SaveViewContainer
            savedViewsList={savedViewsList}
            tabOnChange={tabOnChange}
            activeView={activeView}
            setActiveView={setActiveView}
            changedViewOptions={changedViewOptions}
            constructFilterPayload={constructFilterPayload}
          />
        </Deck.Item>
      )}
      <Deck.Item hasOverflow>{children}</Deck.Item>
    </Deck>
  );
};
